import { NgModule } from '@angular/core';
import { CdTimerComponent } from './angular-cd-timer.component';



@NgModule({
  declarations: [CdTimerComponent],
  imports: [
  ],
  exports: [CdTimerComponent]
})
export class CdTimerModule { }
